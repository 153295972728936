import React from 'react';
import Select from 'react-select';

function Fields({ onSpotData }) {
  const [selectedName, setSelectedName] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [spots, setSpots] = React.useState([]); // Состояние для хранения данных из spots2.json

  // Загрузка данных из spots2.json при монтировании компонента
  React.useEffect(() => {
    const fetchSpots = async () => {
      try {
        const response = await fetch('/spots2.json'); // Путь к файлу spots2.json
        if (!response.ok) {
          throw new Error('Ошибка загрузки данных');
        }
        const data = await response.json();
        setSpots(data.spots); // Сохраняем данные в состоянии
      } catch (error) {
        console.error('Ошибка при загрузке spots2.json:', error);
      }
    };

    fetchSpots();
  }, []); // Пустой массив зависимостей означает, что эффект выполнится только при монтировании

  const stylesComponent = () => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 0,
        border: '1px solid #1d1d1f',
        boxSizing: 'border-box',
        padding: '8px',
        background: 'white',
        fontFamily: 'Inter, sans-serif;',
        fontSize: '15px',
        lineHeight: '20px',
        outline: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          borderColor: '#cccccc',
        },
        boxShadow: 0,
      }),
      menu: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 0,
        border: '1px solid #1d1d1f',
        boxSizing: 'border-box',
        background: 'white',
        fontFamily: 'Inter, sans-serif;',
        fontSize: '15px',
        lineHeight: '20px',
        outline: 'none',
        textTransform: 'uppercase',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        fontFamily: 'Inter, sans-serif;',
        fontSize: '15px',
        lineHeight: '20px',
        outline: 'none',
        color: 'black',
        textTransform: 'uppercase',
        background: state.isFocused ? 'rgba(0, 0, 0, 0.2)' : 0,
        '&:active': {
          background: 'rgba(0, 0, 0, 0.2)',
        },
      }),
    };
  };

  const handleNameChange = (selectedOption) => {
    setSelectedName(selectedOption);
    onSpotData(selectedOption);
    const correspondingAddress = spots.find((spot) => spot.name === selectedOption.name);
    setSelectedAddress(correspondingAddress);
  };

  const handleAddressChange = (selectedOption) => {
    setSelectedAddress(selectedOption);
    const correspondingName = spots.find((spot) => spot.address === selectedOption.address);
    setSelectedName(correspondingName);
    onSpotData(selectedOption);
  };

  return (
    <div className="fields">
      <div className="row">
        <label htmlFor="workplace">Название спота</label>
        <div className="form-control fw">
          <Select
            placeholder="Введите название спота"
            name="spotsByName"
            styles={stylesComponent()}
            options={spots}
            value={selectedName}
            onChange={handleNameChange}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            noOptionsMessage={() => 'Пока такой спот не открыли :('}
          />
        </div>
        <p>Указать название спота, например: JETLAG, G34, BELKA, LUMIERE.</p>
      </div>
      <div className="row">
        <label htmlFor="city">Город и адрес</label>
        <div className="form-control fw">
          <Select
            placeholder="Введите город или адрес"
            name="spotsByAddress"
            styles={stylesComponent()}
            options={spots}
            value={selectedAddress}
            onChange={handleAddressChange}
            getOptionLabel={(option) => option.address}
            getOptionValue={(option) => option.address}
            noOptionsMessage={() => 'Пока такой спот не открыли :('}
          />
        </div>
      </div>
    </div>
  );
}

export default Fields;
/*
// Код который использовал статичный файл spots2.json
import React from 'react';
import Select from 'react-select';

import data from './../spots2.json';

function Fields({ onSpotData }) {
  const [selectedName, setSelectedName] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const spots = data.spots;

  const stylesComponent = () => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 0,
        border: '1px solid #1d1d1f',
        boxSizing: 'border-box',
        padding: '8px',
        background: 'white',
        fontFamily: 'Inter, sans-serif;',
        fontSize: '15px',
        lineHeight: '20px',
        outline: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          borderColor: '#cccccc',
        },
        boxShadow: 0,
      }), menu: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 0,
        border: '1px solid #1d1d1f',
        boxSizing: 'border-box',
        background: 'white',
        fontFamily: 'Inter, sans-serif;',
        fontSize: '15px',
        lineHeight: '20px',
        outline: 'none',
        textTransform: 'uppercase',
      }), option: (baseStyles, state) => ({
        ...baseStyles,
        fontFamily: 'Inter, sans-serif;',
        fontSize: '15px',
        lineHeight: '20px',
        outline: 'none',
        color: 'black',
        textTransform: 'uppercase',
        background: state.isFocused ? 'rgba(0, 0, 0, 0.2)' : 0,
        '&:active': {
          background: 'rgba(0, 0, 0, 0.2)',
        },
      }),
    };
  };
  const handleNameChange = (selectedOption) => {
    setSelectedName(selectedOption);
    onSpotData(selectedOption);
    const correspondingAddress = spots.find((spot) => spot.name === selectedOption.name);
    setSelectedAddress(correspondingAddress);
  };

  const handleAddressChange = (selectedOption) => {
    setSelectedAddress(selectedOption);
    const correspondingName = spots.find((spot) => spot.address === selectedOption.address);
    setSelectedName(correspondingName);
    onSpotData(selectedOption);
  };

  return (<div className="fields">
    <div className="row">
      <label htmlFor="workplace">Название спота</label>
      <div className="form-control fw">
        <Select
          placeholder="Введите название спота"
          name="spotsByName"
          styles={stylesComponent()}
          options={spots}
          value={selectedName}
          onChange={handleNameChange}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          noOptionsMessage={() => 'Пока такой спот не открыли :('}
        />
      </div>
      <p>Указать название спота, например: JETLAG, G34, BELKA, LUMIERE.</p>
    </div>
    <div className="row">
      <label htmlFor="city">Город и адрес</label>
      <div className="form-control fw">
        <Select
          placeholder="Введите город или адрес"
          name="spotsByAddress"
          styles={stylesComponent()}
          options={spots}
          value={selectedAddress}
          onChange={handleAddressChange}
          getOptionLabel={(option) => option.address}
          getOptionValue={(option) => option.address}
          noOptionsMessage={() => 'Пока такой спот не открыли :('}
        />
      </div>
    </div>
  </div>);
}

export default Fields;
*/