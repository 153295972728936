import React from 'react';

function SubmitButton({onClick, disabled}) {
  return (
    <div className="row">
      <button type="submit" className="submit-form" style={{cursor: 'pointer'}} onClick={onClick} disabled={disabled}>
        {disabled ? 'Загрузка...' : 'Сохранить'}
      </button>
    </div>
  );
}

export default SubmitButton;
